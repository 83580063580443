import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getFormStep,
    setProjectId,
    setProjectData,
    getProjectData,
    setSubmittalId,
    setSubmittalData,
    setCustomerData,
    getSubmittalData,
    getCustomerData,
} from '../../../helpers/handleFormStep';
import { setProductsInCart, updateListOfProducts } from '../../../helpers/handleCartOfProducts';
import { extractError } from '../../../helpers/api';
import { getProductsInCart } from '../../../helpers/handleCartOfProducts';
import SubmittalService from '../../../services/SubmittalService';


const model = {
    step: getFormStep() || 0,
    projectData: getProjectData() || null,
    submittalData: getSubmittalData() || null,
    customerData: getCustomerData() || null,
    categoryList: [],
    productList: [],
    projectListOptions: [],
    productsInCart: getProductsInCart() || [],
    totalItems: 0,
    submitting: false,
    errors: null,
    message: '',
    loading: false,
};

export const submitProjectData = createAsyncThunk(
    'submittal/submitProjectData',
    async (data, thunkApi) => {
        try {
            const response = await SubmittalService.submitProjectData(data);
            const {
                project_id: projectId,
                project_name: projectName,
                description: projectDescription,
                submission_id: submittalId,
                products: productsInCart,
            } = response.data;
            setProjectId(projectId);
            setProjectData({
                project_name: projectName,
                description: projectDescription,
            })
            setSubmittalId(submittalId);
            updateListOfProducts(productsInCart);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const submitSubmittalData = createAsyncThunk(
    'submittal/submitSubmittalData',
    async (data, thunkApi) => {
        try {
            const response = await SubmittalService.createOrUpdateSubmittal(data);
            const {
                customer_data: customerData,
                submittal_data: submittalData,
                products: productsInCart,
            } = response.data;
            setSubmittalData(submittalData);
            setSubmittalId(submittalData.id);
            setCustomerData(customerData);
            updateListOfProducts(productsInCart);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const submitSubmittalCoverLetterData = createAsyncThunk(
    'submittal/submitSubmittalCoverLetterData',
    async (data, thunkApi) => {
        try {
            const response = await SubmittalService.submitCoverLetterData(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getTreeCategoryList = createAsyncThunk(
    'submittal/getCategoryList',
    async (_, thunkApi) => {
        try {
            const response = await SubmittalService.getTreeCategoryList();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const viewProject = createAsyncThunk(
    'submittal/viewProject',
    async (projectId, thunkApi) => {
        try {
            const response = await SubmittalService.viewProject(projectId);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const editProject = createAsyncThunk(
    'submittal/editProject',
    async (data, thunkApi) => {
        try {
            const { project_id: projectId } = data;
            const response = await SubmittalService.updateProject(projectId, data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getProductByCategory = createAsyncThunk(
    'submittal/getProductByCategory',
    async (data, thunkApi) => {
        try {
            const response = await SubmittalService.getProductsByCategory(data);
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const getProjectListOptions = createAsyncThunk(
    'submittal/getProjectListOptions',
    async (_, thunkApi) => {
        try {
            const response = await SubmittalService.getProjectListOptions();
            return response.data;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const viewSubmittal = createAsyncThunk(
    'submittal/viewSubmittal',
    async (submittalId, thunkApi) => {
        try {
            const response = await SubmittalService.viewSubmittal(submittalId);
            const {
                products: productsInCart,
            } = response.data;
            productsInCart && setProductsInCart(productsInCart);
            return {
                products: getProductsInCart(),
                customer_data: response.data.customer_data,
                project_name: response.data.project_name,
                description: response.data.description,
                submittal_data: response.data.submittal_data,
            }
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

export const deleteSubmittal = createAsyncThunk(
    'submittal/deleteSubmittal',
    async (submittalId, thunkApi) => {
        try {
            await SubmittalService.deleteSubmittal(submittalId);
            return submittalId;
        } catch (error) {
            return thunkApi.rejectWithValue(extractError(error));
        }
    },
);

function addNewOption(columnType, options, newOption) {
    if (columnType === 'length') {
        const optionExists = options.some((option) => option.cut_length.toLowerCase() === newOption.cut_length.toLowerCase());
        if (optionExists) {
            return options;
        }
        return [...options, newOption];
    } else {
        const optionExists = options.some((option) => option.material_type.toLowerCase() === newOption.material_type.toLowerCase());
        if (optionExists) {
            return options;
        }
        return [...options, newOption];
    }
};

const createSubmittalSlice = createSlice({
    name: 'createSubmittal',
    initialState: model,
    reducers: {
        setReduxFormStep: (state, action) => {
            state.step = action.payload;
        },
        setProjectDataToRedux: (state, action) => {
            state.projectData = action.payload;
        },
        updateProductsOrder: (state, action) => {
            const activeIndex = state.productsInCart.findIndex((record) => record.key === action.payload.activeId);
            const overIndex = state.productsInCart.findIndex((record) => record.key === action.payload.overId);

            // Ensure indices are valid and not the same
            // if (activeIndex === -1 || overIndex === -1 || activeIndex === overIndex) {
            //     return; // Exit if the indices are invalid or if there's no change needed
            // }

            // Create a copy of the current list
            const newDataSource = [...state.productsInCart];

            // Remove the item from the current position
            const [movedItem] = newDataSource.splice(activeIndex, 1);

            // Insert the item at the new position
            newDataSource.splice(overIndex, 0, movedItem);

            // Reassign the order and key properties for all items
            newDataSource.forEach((item, index) => {
                item.order = index + 1; // Assign order as index + 1
                item.key = String(index); // Update key to match the new order (convert to string if needed)
            });

            // Update the state
            state.productsInCart = newDataSource;
        },
        updateProductCutLengths: (state, action) => {
            state.productsInCart = state.productsInCart.map(product => {
                if (product.product_id === action.payload.product_id
                    && product.category_id === action.payload.category_id) {
                    return {
                        ...product,
                        cut_length: action.payload.inputValue,
                        cut_lengths: addNewOption(
                            'length',
                            product.cut_lengths,
                            {id: action.payload.inputValue, cut_length: action.payload.inputValue}
                        ),
                    };
                }
                return product;
            });
        },
        updateProductCutLength: (state, action) => {
            state.productsInCart = state.productsInCart.map(product => {
                if (product.product_id === action.payload.product_id
                    && product.category_id === action.payload.category_id) {
                    return {...product, cut_length: action.payload.value};
                }
                return product;
            });
        },
        updateProductMaterialType: (state, action) => {
            state.productsInCart = state.productsInCart.map(product => {
                if (product.product_id === action.payload.product_id
                    && product.category_id === action.payload.category_id) {
                    return {
                        ...product,
                        material_type: action.payload.value,
                    };
                }
                return product;
            });
        },
        updateProductMaterialTypes: (state, action) => {
            state.productsInCart = state.productsInCart.map(product => {
                if (product.product_id === action.payload.product_id
                    && product.category_id === action.payload.category_id) {
                    return {
                        ...product,
                        material_type: action.payload.inputValue,
                        material_types: addNewOption(
                            'material',
                            product.material_types,
                            {id: action.payload.inputValue, material_type: action.payload.inputValue}
                        ),
                    };
                }
                return product;
            });
        },
        updateProductQuantity: (state, action) => {
            state.productsInCart = state.productsInCart.map(product => {
                if (product.product_id === action.payload.product_id
                    && product.category_id === action.payload.category_id) {
                    return {...product, quantity: action.payload.quantity};
                }
                return product;
            });
        },
        deleteReduxCartProducts: (state, action) => {
            state.productsInCart = state.productsInCart.filter(
                product => !(product.product_id === action.payload.product_id
                && product.category_id === action.payload.category_id));
        },
        clearProductList: (state) => {
            state.productList = [];
            state.totalItems = 0;
        },
        setProductsToReduxCart: (state, action) => {
            state.productsInCart = action.payload;
        },
        clearReduxProductsInCart: (state) => {
            state.productsInCart = [];
        },
        clearReduxProjectData: (state) => {
            state.projectData = null;
        },
        clearReduxCustomerData: (state) => {
            state.customerData = null;
        },
        clearReduxSubmittalData: (state) => {
            state.submittalData = null;
        },
        resetProjectStateOnSuccess: (state) => {
            state.message = '';
        },
        resetProjectStateOnError: (state) => {
            state.errors = null;
        },
        resetSubmittalStateOnSuccess: (state) => {
            state.message = '';
        },
        resetSubmittalStateOnError: (state) => {
            state.errors = null;
        }
    },
    extraReducers: (builder) => {
        // submit project data
        builder.addCase(submitProjectData.pending, (state) => {
            state.submitting = true;
            state.errors = null;
        });
        builder.addCase(submitProjectData.fulfilled, (state, action) => {
            state.submitting = false;
            state.projectData = {'project_name': action.payload.project_name, 'description': action.payload.description};
            state.productsInCart = action.payload.products;
            state.message = 'Project submitted successfully!';
        });
        builder.addCase(submitProjectData.rejected, (state, action) => {
            state.submitting = false;
            state.errors = action.payload;
        });
        // submit submittal data
        builder.addCase(submitSubmittalData.pending, (state) => {
            state.submitting = true;
            state.errors = null;
        });
        builder.addCase(submitSubmittalData.fulfilled, (state, action) => {
            state.submitting = false;
            state.submittalData = action.payload.submittal_data;
            state.productsInCart = action.payload.products;
            state.message = 'Submittal submitted successfully!';
        });
        builder.addCase(submitSubmittalData.rejected, (state, action) => {
            state.submitting = false;
            state.errors = action.payload;
        });
        // submit submittal cover letter data
        builder.addCase(submitSubmittalCoverLetterData.pending, (state) => {
            state.submitting = true;
            state.errors = null;
        });
        builder.addCase(submitSubmittalCoverLetterData.fulfilled, (state, action) => {
            state.submitting = false;
            state.message = 'Cover letter uploaded successfully!';
        });
        builder.addCase(submitSubmittalCoverLetterData.rejected, (state, action) => {
            state.submitting = false;
            state.errors = action.payload;
        });
        // get category tree list
        builder.addCase(getTreeCategoryList.pending, (state) => {
            state.loading = true;
        }).addCase(getTreeCategoryList.fulfilled, (state, action) => {
            state.loading = false;
            state.categoryList = action.payload;
        }).addCase(getTreeCategoryList.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
        // view project
        builder.addCase(viewProject.pending, (state) => {
            state.submitting = true;
            state.errors = null;
        });
        builder.addCase(viewProject.fulfilled, (state, action) => {
            state.submitting = false;
            state.projectData = action.payload;
        });
        builder.addCase(viewProject.rejected, (state, action) => {
            state.submitting = false;
            state.errors = action.payload;
        });
        // edit project
        builder.addCase(editProject.pending, (state) => {
            state.submitting = true;
            state.errors = null;
        });
        builder.addCase(editProject.fulfilled, (state) => {
            state.submitting = false;
            state.message = 'Project updated successfully!';
        });
        builder.addCase(editProject.rejected, (state, action) => {
            state.submitting = false;
            state.errors = action.payload;
        });
        // get product by category
        builder.addCase(getProductByCategory.pending, (state) => {
            state.loading = true;
        }).addCase(getProductByCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.productList = action.payload.products;
            state.totalItems = action.payload.total;
        }).addCase(getProductByCategory.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
        // get project list options
        builder.addCase(getProjectListOptions.pending, (state) => {
            state.loading = true;
        }
        ).addCase(getProjectListOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.projectListOptions = action.payload;
        }).addCase(getProjectListOptions.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
        // view submittal
        builder.addCase(viewSubmittal.pending, (state) => {
            state.submitting = true;
            state.errors = null;
        });
        builder.addCase(viewSubmittal.fulfilled, (state, action) => {
            state.submitting = false;
            state.projectData = {'project_name': action.payload.project_name, 'description': action.payload.description};
            state.customerData = action.payload.customer_data;
            state.submittalData = action.payload.submittal_data;
            state.productsInCart = action.payload.products;
        });
        builder.addCase(viewSubmittal.rejected, (state, action) => {
            state.submitting = false;
            state.errors = action.payload;
        });
        // delete submittal
        builder.addCase(deleteSubmittal.pending, (state) => {
            state.loading = true;
            state.errors = null;
        });
        builder.addCase(deleteSubmittal.fulfilled, (state) => {
            state.loading = false;
            state.message = 'Submittal deleted successfully!';
        });
        builder.addCase(deleteSubmittal.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        });
    },
});

export const {
    setReduxFormStep,
    clearProductList,
    setProductsToReduxCart,
    updateProductsOrder,
    updateProductCutLength,
    updateProductCutLengths,
    updateProductMaterialType,
    updateProductMaterialTypes,
    updateProductQuantity,
    deleteReduxCartProducts,
    setProjectDataToRedux,
    clearReduxProductsInCart,
    clearReduxProjectData,
    clearReduxCustomerData,
    clearReduxSubmittalData,
    resetProjectStateOnSuccess,
    resetProjectStateOnError,
    resetSubmittalStateOnSuccess,
    resetSubmittalStateOnError,
} = createSubmittalSlice.actions;

export default createSubmittalSlice.reducer;
